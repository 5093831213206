import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonCustom = _resolveComponent("ButtonCustom")!
  const _component_LabelCustom = _resolveComponent("LabelCustom")!
  const _component_DivCol = _resolveComponent("DivCol")!
  const _component_DivRow = _resolveComponent("DivRow")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_dinh_muc_co_dinh_vat_tu_table = _resolveComponent("dinh-muc-co-dinh-vat-tu-table")!
  const _component_PageRowLayout = _resolveComponent("PageRowLayout")!

  return (_ctx.dinhMucCoDinhDetail)
    ? (_openBlock(), _createBlock(_component_PageRowLayout, { key: 0 }, {
        "page-row-header": _withCtx(() => [
          _createTextVNode(" Định mức cố định ")
        ]),
        "page-row-action-button": _withCtx(() => [
          (_ctx.createPhieuXuatShow)
            ? (_openBlock(), _createBlock(_component_ButtonCustom, {
                key: 0,
                "button-type": _ctx.ButtonsType.RECEIPT,
                "color-type": _ctx.ButtonTypeColors.SECONDARY,
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push(`/quan-ly/bien-ban-giai-the/${_ctx.idBienBan}/phieu-xuat/tao-moi?phieu-xuat-le=true&loai-dinh-muc=DMCD`)))
              }, {
                "button-label": _withCtx(() => [
                  _createTextVNode("Tạo phiếu xuất")
                ]),
                _: 1
              }, 8, ["button-type", "color-type"]))
            : _createCommentVNode("", true)
        ]),
        "page-row-content": _withCtx(() => [
          _createVNode(_component_DivRow, { class: "mb-2" }, {
            default: _withCtx(() => [
              _createVNode(_component_DivCol, { class: "d-flex align-items-center" }, {
                default: _withCtx(() => [
                  _createVNode(_component_LabelCustom, { "is-text-muted": true }, {
                    default: _withCtx(() => [
                      _createTextVNode(" ID: ")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_DivCol, { class: "d-flex align-items-center" }, {
                default: _withCtx(() => [
                  _createVNode(_component_LabelCustom, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.dinhMucCoDinhDetail.id), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_DivCol, {
                cols: 2,
                class: "d-flex justify-content-end align-items-center"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_LabelCustom, {
                    "is-text-muted": true,
                    class: "text-right"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Ngày ban hành: ")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_DivCol, {
                cols: 2,
                class: "d-flex justify-content-end align-items-center"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_LabelCustom, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.formatDisplayingDate(_ctx.dinhMucCoDinhDetail.ngay_ban_hanh)), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_DivCol, {
                cols: 2,
                class: "d-flex justify-content-end align-items-center"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_LabelCustom, { "is-text-muted": true }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Ngày hết hạn: ")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_DivCol, { cols: 2 }, {
                default: _withCtx(() => [
                  _createVNode(_component_LabelCustom, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.formatDisplayingDate(_ctx.dinhMucCoDinhDetail.ngay_het_han)), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_DivRow, { class: "mb-3" }, {
            default: _withCtx(() => [
              _createVNode(_component_DivCol, null, {
                default: _withCtx(() => [
                  _createVNode(_component_LabelCustom, { "is-text-muted": true }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Mô tả: ")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_DivCol, { cols: 3 }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    modelValue: _ctx.dinhMucCoDinhDetail.mo_ta,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.dinhMucCoDinhDetail.mo_ta) = $event)),
                    rows: 2,
                    type: "textarea",
                    disabled: ""
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_dinh_muc_co_dinh_vat_tu_table, {
            items: _ctx.dinhMucCoDinhDetail.vat_tu ? _ctx.dinhMucCoDinhDetail.vat_tu : [],
            "is-loading": false,
            "show-price-and-total": false
          }, null, 8, ["items"])
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}