import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BienBanGiaiThePrintModal = _resolveComponent("BienBanGiaiThePrintModal")!
  const _component_ConfirmHoanThanh = _resolveComponent("ConfirmHoanThanh")!
  const _component_ButtonCustom = _resolveComponent("ButtonCustom")!
  const _component_LabelCustom = _resolveComponent("LabelCustom")!
  const _component_DivCol = _resolveComponent("DivCol")!
  const _component_DivRow = _resolveComponent("DivRow")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_PageRowLayout = _resolveComponent("PageRowLayout")!
  const _component_DinhMucCoDinhSection = _resolveComponent("DinhMucCoDinhSection")!
  const _component_AddVatTuTableItems = _resolveComponent("AddVatTuTableItems")!
  const _component_PhieuXuatKhoTable = _resolveComponent("PhieuXuatKhoTable")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.bienBanDetail)
      ? (_openBlock(), _createBlock(_component_BienBanGiaiThePrintModal, {
          key: 0,
          ref: "bienBanGiaiThePrintModalRef",
          "bien-ban-data": _ctx.bienBanDetail,
          onOnCloseModal: _ctx.onClosePrintModal
        }, null, 8, ["bien-ban-data", "onOnCloseModal"]))
      : _createCommentVNode("", true),
    _createVNode(_component_ConfirmHoanThanh, {
      "bien-ban-data": _ctx.bienBanDetail,
      show: _ctx.confirmHoanThanhBienBan,
      onConfirm: _ctx.hoanThanhHander,
      onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.confirmHoanThanhBienBan = false))
    }, null, 8, ["bien-ban-data", "show", "onConfirm"]),
    _createVNode(_component_PageRowLayout, null, {
      "page-row-header": _withCtx(() => [
        _createTextVNode(" Chi tiết ")
      ]),
      "page-row-action-button": _withCtx(() => [
        (!_ctx.bienBanDetail?.ngay_ra_xuong)
          ? (_openBlock(), _createBlock(_component_ButtonCustom, {
              key: 0,
              "button-type": _ctx.ButtonsType.FA_CHECK_SQUARE,
              "color-type": _ctx.ButtonTypeColors.PRIMARY,
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.confirmHoanThanhBienBan = true))
            }, {
              "button-label": _withCtx(() => [
                _createTextVNode(" Hoàn thành ")
              ]),
              _: 1
            }, 8, ["button-type", "color-type"]))
          : _createCommentVNode("", true),
        _createVNode(_component_ButtonCustom, {
          "button-type": _ctx.ButtonsType.ADD,
          "color-type": _ctx.ButtonTypeColors.SECONDARY,
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.push(`/quan-ly/bien-ban-giai-the/${_ctx.idBienBan}/tao-bbgt-bo-sung`)))
        }, {
          "button-label": _withCtx(() => [
            _createTextVNode(" BBGT bổ sung ")
          ]),
          _: 1
        }, 8, ["button-type", "color-type"]),
        _createVNode(_component_ButtonCustom, {
          "button-type": _ctx.ButtonsType.FA_PRINT,
          "color-type": _ctx.ButtonTypeColors.INFO,
          onClick: _ctx.onPrintButtonClicked
        }, null, 8, ["button-type", "color-type", "onClick"]),
        (!_ctx.bienBanDetail?.ngay_ra_xuong)
          ? (_openBlock(), _createBlock(_component_ButtonCustom, {
              key: 1,
              "button-type": _ctx.ButtonsType.EDIT,
              "color-type": _ctx.ButtonTypeColors.SUCCESS,
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.push(`/quan-ly/bien-ban-giai-the/cap-nhat/${_ctx.idBienBan}`)))
            }, null, 8, ["button-type", "color-type"]))
          : _createCommentVNode("", true),
        _createVNode(_component_ButtonCustom, {
          "button-type": _ctx.ButtonsType.BACK,
          onClick: _ctx.goBack
        }, null, 8, ["button-type", "onClick"])
      ]),
      "page-row-content": _withCtx(() => [
        _createVNode(_component_DivRow, { class: "mb-3" }, {
          default: _withCtx(() => [
            _createVNode(_component_DivCol, null, {
              default: _withCtx(() => [
                _createVNode(_component_LabelCustom, { "is-text-muted": true }, {
                  default: _withCtx(() => [
                    _createTextVNode("ID biên bản:")
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_DivCol, null, {
              default: _withCtx(() => [
                _createVNode(_component_LabelCustom, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.bienBanDetail?.id), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_DivRow, { class: "mb-3" }, {
          default: _withCtx(() => [
            _createVNode(_component_DivCol, null, {
              default: _withCtx(() => [
                _createVNode(_component_LabelCustom, { "is-text-muted": true }, {
                  default: _withCtx(() => [
                    _createTextVNode("Ngày lập:")
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_DivCol, { cols: 2 }, {
              default: _withCtx(() => [
                _createVNode(_component_LabelCustom, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.moment(_ctx.bienBanDetail?.ngay_lap).format('DD-MM-YYYY')), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_DivCol, { cols: 2 }, {
              default: _withCtx(() => [
                _createVNode(_component_LabelCustom, { "is-text-muted": true }, {
                  default: _withCtx(() => [
                    _createTextVNode("Ngày vào xưởng:")
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_DivCol, { cols: 2 }, {
              default: _withCtx(() => [
                _createVNode(_component_LabelCustom, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.moment(_ctx.bienBanDetail?.ngay_vao_xuong).format('DD-MM-YYYY')), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_DivCol, { cols: 2 }, {
              default: _withCtx(() => [
                _createVNode(_component_LabelCustom, { "is-text-muted": true }, {
                  default: _withCtx(() => [
                    _createTextVNode("Ngày ra xưởng:")
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_DivCol, { cols: 2 }, {
              default: _withCtx(() => [
                _createVNode(_component_LabelCustom, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.bienBanDetail?.ngay_ra_xuong ? _ctx.moment(_ctx.bienBanDetail.ngay_ra_xuong).format('DD-MM-YYYY') : '-'), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_DivRow, { class: "mb-3" }, {
          default: _withCtx(() => [
            _createVNode(_component_DivCol, null, {
              default: _withCtx(() => [
                _createVNode(_component_LabelCustom, { "is-text-muted": true }, {
                  default: _withCtx(() => [
                    _createTextVNode("Số biên bản:")
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_DivCol, null, {
              default: _withCtx(() => [
                _createVNode(_component_LabelCustom, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.bienBanDetail?.so_bien_ban), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_DivCol),
            _createVNode(_component_DivCol, null, {
              default: _withCtx(() => [
                _createVNode(_component_LabelCustom, { "is-text-muted": true }, {
                  default: _withCtx(() => [
                    _createTextVNode("Toa xe:")
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_DivCol, { cols: 3 }, {
              default: _withCtx(() => [
                _createVNode(_component_router_link, {
                  to: `/quan-ly/toa-xe/${_ctx.bienBanDetail?.id_toa_xe}/chi-tiet`
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.bienBanDetail?.toa_xe.ma_toa_xe) + " - " + _toDisplayString(_ctx.bienBanDetail?.toa_xe.ten_toa_xe), 1)
                  ]),
                  _: 1
                }, 8, ["to"])
              ]),
              _: 1
            }),
            _createVNode(_component_DivCol, { cols: 2 }, {
              default: _withCtx(() => [
                _createVNode(_component_LabelCustom, { "is-text-muted": true }, {
                  default: _withCtx(() => [
                    _createTextVNode("Tiêu đề kế hoạch:")
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_DivCol, { cols: 2 }, {
              default: _withCtx(() => [
                (_ctx.bienBanDetail?.id_ke_hoach_sua_chua)
                  ? (_openBlock(), _createBlock(_component_router_link, {
                      key: 0,
                      to: `/quan-ly/ke-hoach-sua-chua/chi-tiet/${_ctx.bienBanDetail?.id_ke_hoach_sua_chua}`
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.bienBanDetail?.ke_hoach_sua_chua?.ten_ke_hoach), 1)
                      ]),
                      _: 1
                    }, 8, ["to"]))
                  : (_openBlock(), _createBlock(_component_LabelCustom, { key: 1 }, {
                      default: _withCtx(() => [
                        _createTextVNode("Không có")
                      ]),
                      _: 1
                    }))
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_DivRow, null, {
          default: _withCtx(() => [
            _createVNode(_component_DivCol, { class: "d-flex align-items-center" }, {
              default: _withCtx(() => [
                _createVNode(_component_LabelCustom, { "is-text-muted": true }, {
                  default: _withCtx(() => [
                    _createTextVNode("Được duyệt?")
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_DivCol, { cols: 1 }, {
              default: _withCtx(() => [
                (_ctx.bienBanDetail)
                  ? (_openBlock(), _createBlock(_component_el_checkbox, {
                      key: 0,
                      id: "is-hoan-thanh",
                      modelValue: _ctx.bienBanDetail.is_approved,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.bienBanDetail.is_approved) = $event)),
                      disabled: "",
                      size: "large"
                    }, null, 8, ["modelValue"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }),
            _createVNode(_component_DivCol),
            _createVNode(_component_DivCol, { class: "d-flex align-items-center" }, {
              default: _withCtx(() => [
                _createVNode(_component_LabelCustom, { "is-text-muted": true }, {
                  default: _withCtx(() => [
                    _createTextVNode("Định mức động?")
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_DivCol, { cols: 3 }, {
              default: _withCtx(() => [
                (_ctx.bienBanDetail)
                  ? (_openBlock(), _createBlock(_component_el_checkbox, {
                      key: 0,
                      id: "is-hoan-thanh",
                      modelValue: _ctx.bienBanDetail.has_dinh_muc_dong,
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.bienBanDetail.has_dinh_muc_dong) = $event)),
                      disabled: "",
                      size: "large"
                    }, null, 8, ["modelValue"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }),
            _createVNode(_component_DivCol, {
              cols: 2,
              class: "d-flex align-items-center"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_LabelCustom, { "is-text-muted": true }, {
                  default: _withCtx(() => [
                    _createTextVNode("Cấp sửa chữa")
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_DivCol, {
              cols: 3,
              additionalClass: "align-items-center d-flex"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_LabelCustom, { class: "align-items-center d-flex" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.bienBanDetail?.cap_sua_chua?.ten_cap_sc ||
            _ctx.bienBanDetail?.ke_hoach_sua_chua.cap_sua_chua.ten_cap_sc), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        (_ctx.bienBanParentDetail)
          ? (_openBlock(), _createBlock(_component_DivRow, { key: 0 }, {
              default: _withCtx(() => [
                _createVNode(_component_DivCol, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_LabelCustom, { "is-text-muted": true }, {
                      default: _withCtx(() => [
                        _createTextVNode("Ghi chú")
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_DivCol, { cols: 5 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_LabelCustom, { class: "align-items-center d-flex" }, {
                      default: _withCtx(() => [
                        _createTextVNode(" Đây là BB bổ sung của biên bản số "),
                        _createVNode(_component_RouterLink, {
                          class: "ms-1",
                          to: `/quan-ly/bien-ban-giai-the/chi-tiet/${_ctx.idBienBan}`
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.bienBanParentDetail.so_bien_ban), 1)
                          ]),
                          _: 1
                        }, 8, ["to"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    (_ctx.idCapSuaChua && _ctx.idDanhMucToaXe)
      ? (_openBlock(), _createBlock(_component_DinhMucCoDinhSection, {
          key: 1,
          "create-phieu-xuat-show": !_ctx.bienBanDetail?.ngay_ra_xuong,
          "id-bien-ban": _ctx.idBienBan,
          "id-cap-sua-chua": _ctx.idCapSuaChua,
          "id-danh-muc-toa-xe": _ctx.idDanhMucToaXe,
          onOnDoneLoading: _cache[6] || (_cache[6] = ($event: any) => (_ctx.endLoading()))
        }, null, 8, ["create-phieu-xuat-show", "id-bien-ban", "id-cap-sua-chua", "id-danh-muc-toa-xe"]))
      : _createCommentVNode("", true),
    (_ctx.bienBanDetail?.has_dinh_muc_dong)
      ? (_openBlock(), _createBlock(_component_PageRowLayout, { key: 2 }, {
          "page-row-header": _withCtx(() => [
            _createTextVNode(" Ngoài định mức ")
          ]),
          "page-row-action-button": _withCtx(() => [
            (!_ctx.bienBanDetail?.ngay_ra_xuong)
              ? (_openBlock(), _createBlock(_component_ButtonCustom, {
                  key: 0,
                  "button-type": _ctx.ButtonsType.RECEIPT,
                  "color-type": _ctx.ButtonTypeColors.SECONDARY,
                  onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.$router.push(`/quan-ly/bien-ban-giai-the/${_ctx.idBienBan}/phieu-xuat/tao-moi?phieu-xuat-le=true&loai-dinh-muc=NDM`)))
                }, {
                  "button-label": _withCtx(() => [
                    _createTextVNode("Tạo phiếu xuất")
                  ]),
                  _: 1
                }, 8, ["button-type", "color-type"]))
              : _createCommentVNode("", true)
          ]),
          "page-row-content": _withCtx(() => [
            _createVNode(_component_AddVatTuTableItems, {
              "disable-ghi-chu-input": true,
              "is-delete-button-display": false,
              "is-highlight-unapproved-item": true,
              "is-info-button-display": true,
              "is-loading": false,
              items: _ctx.vatTuItemsDisplay,
              "show-ghi-chu-input": true,
              "show-price-and-total": false
            }, null, 8, ["items"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_PageRowLayout, null, {
      "page-row-header": _withCtx(() => [
        _createTextVNode(" Phiếu Xuất Kho ")
      ]),
      "page-row-content": _withCtx(() => [
        _createVNode(_component_PhieuXuatKhoTable, {
          items: _ctx.bienBanDetail?.phieu_xuat_kho
        }, null, 8, ["items"])
      ]),
      _: 1
    })
  ], 64))
}